import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Data from './Data';

function App() {

  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const handleMouseMove = () => {
      setIsMuted(false);
    }; // A videó némításának eltávolítása

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div>
      <div class="video-background-holder">
        <div class="video-background-overlay"></div>
        <video autoPlay loop muted={isMuted}>
          <source src="https://rákaszívedbe.hu/brendon.mp4" type="video/mp4" />
        </video>
        <div class="video-background-content container h-100">
          <div class="d-flex h-100 text-center align-items-center">
              <div class="w-100 text-white">
                  <h1 class="display-4">
                  <BrowserRouter>
                    <Switch>
                      <Route path="/:adat" component={Data} />
                      <Route path="/" component={Data}/>
                    </Switch>
                  </BrowserRouter>
                  </h1>
                  <p class="lead line2 mb-0">With HTML and Bootstrap</p>
              </div>
          </div>
        </div>
      </div>
      <div className="footer">
        Made by: <a className='ref' href='https://xyl.lol/vlevente' >VLevente</a>
      </div>
    </div>
  );
}

export default App;
