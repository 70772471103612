import React from 'react';
import { useParams } from 'react-router-dom';

function Data() {
  // Használjuk a useParams hook-ot az URL paraméterek eléréséhez
  const { adat } = useParams();

  if (adat === undefined) {
    return (
      <div>
        <h2>Rák a szívedbe!</h2>
      </div>
    );
  }else{
    return (
      <div>
        <h2>Rák a szívedbe {adat}!</h2>
      </div>
    );
  }
}

export default Data;
